<template>
  <div class="relative w-full cursor-pointer px-1 md:min-h-16" @click="isItemsVisible = !isItemsVisible">
    <div class="flex w-full gap-2 md:gap-4">
      <span
        v-if="!hideVerticalLine"
        class="absolute bottom-2 left-4 top-[30px] w-[1.5px] -translate-x-1/2 bg-primary-60"
      />
      <span
        class="text-caption-3 flex size-5 shrink-0 items-center justify-center rounded-full bg-primary-100 text-white md:size-6"
      >
        {{ groupIndex + 1 }}
      </span>
      <div class="flex w-full flex-col gap-1">
        <PropertyTabsPaymentPlanTitle :plan-group :rotate-icon="isItemsVisible" />

        <template v-if="isItemsVisible">
          <PropertyTabsPaymentPlanItem v-for="(it, i) in planGroup.items" :key="i" :plan-item="it" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PaymentPlanGroupFormatted } from '~/types/properties'

const props = defineProps<{
  planGroup: PaymentPlanGroupFormatted
  groupIndex: number
  hideVerticalLine?: boolean
  planIndex?: number
  activeSlide?: number
}>()

const isItemsVisible = ref(props.groupIndex === 0)

watch(
  () => props.activeSlide,
  (_, oldValue) => {
    if (oldValue === props.planIndex && props.groupIndex !== 0) {
      isItemsVisible.value = false
    }
  }
)
</script>

<style scoped></style>
