<template>
  <div class="relative w-[312px] cursor-pointer overflow-hidden rounded-xl border border-dm-50">
    <div class="p-2 md:p-4">
      <div class="mb-3 flex gap-2">
        <PropertyTabsUnitTag
          :label="item.rooms_count.en.length ? item.rooms_count.en : PROPERTIES_ROOMS_MAP[item.key]"
        />
        <PropertyTabsUnitTag v-if="item.type.en.length" :label="item.type.en" />
      </div>
      <img :src="item.levels_photos[0].image" alt="plan" class="mx-auto h-44 w-56" />
    </div>
    <div class="bg-black-03 p-2 md:p-4">
      <p class="text-subhead-4 mb-1.5">
        AED {{ aedFormatter.format(Number(item.price.min.base?.value)) }} - AED
        {{ aedFormatter.format(Number(item.price.max.base?.value)) }}
      </p>
      <p v-if="item.price.min.user" class="text-subhead-4 mb-1.5">
        {{ authStore.getUserCurrencyCode }} ~{{ aedFormatter.format(Number(item.price.min.user?.value)) }} -
        {{ authStore.getUserCurrencyCode }} ~{{ aedFormatter.format(Number(item.price.max.user?.value)) }}
      </p>
      <div class="text-body-2 flex items-center gap-2">
        <p>Type {{ index + 1 }}</p>
        •
        <p>{{ item.sale_units_count }} units</p>
        •
        <p>from {{ item.area_min.m2 }} {{ useGetUserMeasure() }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropertyTypes } from '@/types'
import { PROPERTIES_ROOMS_MAP } from '@/constants'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
})
type Props = {
  item: PropertyTypes.Apartment
  index: number
}
defineProps<Props>()
</script>

<style scoped></style>
