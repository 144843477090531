<template>
  <div>
    <div v-if="units.length" class="hidden rounded-2xl border border-dm-50 md:block">
      <UiStickyColumnsTable :items="units" :columns="columns" class="pt-3" @clicked="emits('open-units')">
        <template #title="{ item }">
          <p class="text-subhead-4">{{ item.title }}</p>
        </template>
        <template #area_min="{ item }">
          <span>from {{ item.area_min.toFixed(0) }} {{ useGetUserMeasure() }}</span>
        </template>
        <template #min_price_m2="{ item }">
          <div>
            <span class="text-subhead-3">AED</span>
            from {{ getMinPriceBase(item.name) }}
          </div>
          <div v-if="!!getMinPriceUser(item.name)">
            <span class="text-subhead-3">{{ authStore.getUserCurrencyCode }}</span>
            from {{ getMinPriceUser(item.name) }}
          </div>
        </template>
        <template #price_min="{ item }">
          <div class="flex items-center justify-between">
            <div>
              <div>
                <span class="text-subhead-3">AED</span> from
                {{ aedFormatter.format(item.price_min_base) }}
              </div>

              <div v-if="item.price_min_user">
                <span class="text-subhead-3">{{ authStore.getUserCurrencyCode }}</span> from
                {{ aedFormatter.format(item.price_min_user) }}
              </div>
            </div>
            <button class="p-1.5"><UiIcon name="chevron-small-right" class="!size-4 text-black-90" /></button>
          </div>
        </template>
      </UiStickyColumnsTable>
    </div>
    <div class="md:hidden">
      <client-only>
        <Swiper navigation :slides-per-view="1" :modules="[SwiperNavigation]">
          <SwiperSlide v-for="(unit, index) in units" :key="index">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row justify-center">
                {{ unit.title }}
              </div>
              <div class="flex flex-col gap-2 rounded-xl border border-solid border-primary-20 p-3">
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="area" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Area, {{ useGetUserMeasure() }}</span>
                    <span class="text-body-2">{{ unit.area_min.toFixed(0) }}+ {{ useGetUserMeasure() }}</span>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="money-banknotes" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Price per {{ useGetUserMeasure() }}</span>
                    <span class="text-body-2">AED {{ getMinPriceBase(unit.name) }}+</span>
                    <span v-if="getMinPriceUser(unit.name)" class="text-body-2"
                      >{{ authStore.getUserCurrencyCode }} ~{{ getMinPriceUser(unit.name) }}+</span
                    >
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="treemap" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">№ of units</span>
                    <span class="text-body-2">{{ unit.count }}</span>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="flex size-[34px] items-center justify-center rounded bg-primary-05 text-primary-100">
                    <UiIcon name="home" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-caption-3 text-black-60">Unit price</span>
                    <span class="text-body-2">AED {{ aedFormatter.format(unit.price_min_base) }}+</span>
                    <span v-if="unit.price_min_user" class="text-body-2"
                      >{{ authStore.getUserCurrencyCode }} ~{{ aedFormatter.format(unit.price_min_user) }}+</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropertyTypes } from '@/types'
import { useAuthStore } from '~/store/auth'

const emits = defineEmits(['open-units'])

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

const authStore = useAuthStore()

type Props = {
  propertyDetailed: PropertyTypes.PropertyDetailed
}

const props = defineProps<Props>()

const getMinPriceBase = (name: string) => {
  const unit = props.propertyDetailed.br_prices.find((unit) => unit.key === name.split('_')[1])
  if (!unit) return ' '
  return aedFormatter.format(Number(unit.min_price_m2.base?.price))
}

const getMinPriceUser = (name: string) => {
  const unit = props.propertyDetailed.br_prices.find((unit) => unit.key === name.split('_')[1])
  if (!unit?.min_price_m2.user) return null
  return aedFormatter.format(Number(unit.min_price_m2.user?.price))
}

const units = computed(() => {
  if (!props.propertyDetailed.apartments.length) return []
  return getPropertyUnits(props.propertyDetailed.apartments)
})

const columns = [
  { text: 'Property type', slot: 'title', value: 'title' },
  { text: `Area, ${useGetUserMeasure()}`, slot: 'area_min', value: 'area_min' },
  { text: `Price per ${useGetUserMeasure()}`, slot: 'min_price_m2', value: 'min_price_m2' },
  { text: '№ of units', value: 'count' },
  { text: 'Unit price', slot: 'price_min', value: 'price_min' },
]
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply -top-4 mt-0 size-5 -translate-y-full;
      @media screen and (max-width: 1024px) {
        @apply top-5 size-4;
      }
      &:after {
        @apply text-sm text-black-100;
      }
      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }
    &-button-prev {
      @apply right-8;
      left: initial !important;
      @media screen and (max-width: 1024px) {
        left: 0px !important;
      }
    }
    &-button-next {
      @apply right-0;
    }
  }
}
</style>
