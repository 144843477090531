import { format } from 'date-fns'
import type {
  PaymentPlanGroupFormatted,
  PaymentPlanGroup,
  PaymentPlanItem,
  PaymentPlanInfoFees,
  PaymentPlan,
} from '~/types/properties'

const GROUP_KEYS = {
  booking: 'booking',
  construction: 'construction',
  handover: 'handover',
  post_handover: 'post_handover',
}

const GROUP_TITLES = {
  [GROUP_KEYS.booking]: 'Down payment',
  [GROUP_KEYS.construction]: 'During construction',
  [GROUP_KEYS.handover]: 'On Handover',
  [GROUP_KEYS.post_handover]: 'Post Handover',
}

const ITEMS_KEYS = {
  PAYMENT_PLAN_WHEN_HANDOVER: 'payment_plan_when_handover',
}

export const usePropertyPaymentPlans = (plans: PaymentPlan[]): PaymentPlanGroupFormatted[][] => {
  const getFormattedItems = (items: PaymentPlanGroup[]): PaymentPlanItem[] => {
    const formattedItems = [...items]

    const getRepeatedItems = (items: PaymentPlanGroup[]): PaymentPlanGroup[] => {
      if (!items.length) return []
      const originalItem = { ...items[0] }
      const result: PaymentPlanGroup[] = []

      if (originalItem.when_at) {
        result.push({
          ...originalItem,
          when_at: originalItem.when_at,
        })
      }

      if (!originalItem.repeat_count || !originalItem.repeat_from_at || !originalItem.repeat_to_at) {
        return result
      }

      const currentDate = originalItem.when_at ? new Date(originalItem.when_at) : new Date()

      const repeatToAt = new Date(originalItem.repeat_to_at)
      const periodCount = originalItem.repeat_period_count || 1

      for (let i = 0; i < originalItem.repeat_count - 1; i++) {
        switch (originalItem.repeat_period) {
          case 'month':
            currentDate.setMonth(currentDate.getMonth() + periodCount)
            break
          case 'quarter':
            currentDate.setMonth(currentDate.getMonth() + periodCount * 3)
            break
          case 'year':
            currentDate.setFullYear(currentDate.getFullYear() + periodCount)
            break
          default:
            break
        }

        if (currentDate > repeatToAt) break

        result.push({
          ...originalItem,
          when_at: currentDate.toISOString().slice(0, 19).replace('T', ' '),
        })
      }
      return result
    }

    const result: PaymentPlanGroup[] = []

    formattedItems.forEach((item) => {
      if (item.repeat_count) {
        result.push(...getRepeatedItems([item]))
      } else {
        result.push(item)
      }
    })

    return result.map(({ milestone, percent, key, price, when_at: whenAt }) => {
      const isFee = key.toLowerCase().includes('fee')
      const details = key === ITEMS_KEYS.PAYMENT_PLAN_WHEN_HANDOVER ? 'Handover' : undefined

      const formatDate = (date: string) => {
        if (!date) return undefined

        try {
          return format(new Date(date), 'dd MMM yyyy')
        } catch {
          return undefined
        }
      }

      return {
        key,
        name: milestone,
        percent: price ? `${useMoneyFormatter(price, { notation: 'standard' })}` : `${percent}%`,
        isFee,
        date: formatDate(whenAt),
        details,
      }
    })
  }

  const getPercentage = (info: PaymentPlan['info'], key: keyof typeof GROUP_KEYS) => {
    const PERCENT_KEYS = {
      [GROUP_KEYS.booking]: 'on_booking_percent',
      [GROUP_KEYS.construction]: 'on_construction_percent',
      [GROUP_KEYS.handover]: 'on_handover_percent',
      [GROUP_KEYS.post_handover]: 'post_handover_percent',
    }

    const FEES_KEYS = {
      [GROUP_KEYS.booking]: 'on_booking_fees',
      [GROUP_KEYS.construction]: 'on_construction_fees',
      [GROUP_KEYS.handover]: 'on_handover_fees',
      [GROUP_KEYS.post_handover]: 'post_handover_fees',
    }

    const percent = info[PERCENT_KEYS[key] as keyof typeof info]
    const fee: PaymentPlanInfoFees | undefined = info[FEES_KEYS[key] as keyof typeof info]

    if (!percent) return ''

    const calculatedPercent = Math.round(fee?.percent ? percent - fee.percent : percent)

    return `${calculatedPercent}%`
  }

  const getPaymentsCount = (info: PaymentPlan['info'], key: keyof typeof GROUP_KEYS) => {
    const PAYMENTS_COUNTS_KEYS = {
      [GROUP_KEYS.booking]: 'on_booking_payments_count',
      [GROUP_KEYS.construction]: 'on_construction_payments_count',
      [GROUP_KEYS.handover]: 'on_handover_payments_count',
      [GROUP_KEYS.post_handover]: 'on_post_handover_payments_count',
    }

    const result = info[PAYMENTS_COUNTS_KEYS[key as keyof typeof PAYMENTS_COUNTS_KEYS] as keyof typeof info]

    return result
  }

  return plans.map(({ items, info }) => {
    return Object.entries(items)
      .filter(([key]) => GROUP_KEYS[key as keyof typeof GROUP_KEYS])
      .sort((a, b) => {
        const order = Object.keys(GROUP_KEYS)

        const indexA = order.indexOf(a[0])
        const indexB = order.indexOf(b[0])

        return indexA - indexB
      })
      .map(([key, items]) => {
        const result = {
          name: GROUP_TITLES[key as keyof typeof GROUP_TITLES],
          percent: getPercentage(info, key as keyof typeof GROUP_KEYS),
          key,
          items: getFormattedItems(items),
        } as PaymentPlanGroupFormatted

        result.date = result.items[0].date

        result.payments = getPaymentsCount(info, key as keyof typeof GROUP_KEYS)

        return result
      })
  })
}
