<template>
  <div class="flex flex-col overflow-hidden rounded-2xl border border-dm-20 bg-white">
    <div class="flex items-center justify-between bg-dm-90 p-4 pb-2">
      <div class="flex items-center gap-1.5">
        <p
          class="size-4 rounded-full border-2 border-white"
          :style="{ backgroundColor: nameToColorPropertyMap[item.sales_status.en] }"
        ></p>
        <p class="text-body-2 text-white">{{ item.sales_status.en }}</p>
      </div>
      <!-- <div class="flex gap-3">
        <button id="download-file" @click="emits('download')">
          <UiIcon name="download" class="!size-5 text-white/70" />
        </button>
        <button id="copy-link" @click="emits('copy-link')">
          <UiIcon name="link" class="!size-5 text-white/70" />
        </button>
      </div> -->
    </div>
    <div class="flex flex-col gap-5 px-4 pb-5 pt-3">
      <div>
        <h4 class="mb-1">{{ item.title.en }}</h4>
        <p class="text-body-2 text-black-60">{{ item.address }}</p>
      </div>
      <div class="flex gap-4">
        <img v-if="item.logo" :src="item.logo" alt="logo" class="size-16 rounded-lg" />
        <div>
          <p class="text-subhead-3 mb-1">{{ item.developer.title.en }}</p>
          <p class="text-body-2 mb-0.5">
            {{ item.districts.join(', ') }}
          </p>
        </div>
      </div>
      <button
        v-if="!authStore.getVisitorToken"
        id="open-developer"
        class="text-subhead-4 flex items-center gap-1.5 underline"
        @click="emits('open-developer')"
      >
        <UiIcon name="pin-filled" class="!size-5" /> Sales office contacts
      </button>
      <div>
        <p class="text-body-2 mb-0.5">{{ progressPercentage }}% completed</p>
        <div class="flex items-center gap-2">
          <div class="relative h-1 flex-1 overflow-hidden rounded-sm bg-black-05">
            <p class="absolute h-full rounded-sm bg-primary-100" :style="{ width: progressPercentage + '%' }"></p>
          </div>
          <p v-if="item.planned_completion_at" class="text-subhead-4 text-primary-100">
            {{ format(new Date(item.planned_completion_at), 'QQQ yy') }}
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <UiButtonBase v-if="!hideShareButton" id="share-with-client" class="w-full" @click="$emit('create-offer')"
          ><UiIcon name="link-normal" />Share with client</UiButtonBase
        >
        <UiButtonBase
          v-if="item.brochures?.length"
          id="download-brochure"
          class="w-full"
          type="secondary"
          :disabled="brochureIsLoading"
          @click="$emit('download')"
          ><UiIcon name="download" />Download developer brochure</UiButtonBase
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { PropertyTypes } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

type Props = {
  item: PropertyTypes.PropertyDetailed
  hideShareButton?: boolean
  brochureIsLoading?: boolean
}
const props = defineProps<Props>()

const emits = defineEmits(['download', 'copy-link', 'open-developer', 'create-offer'])

const progressPercentage = computed(() => {
  if (props.item.construction_progress.length) {
    return Number(props.item.construction_progress).toFixed(0)
  }
  return 0
})
</script>

<style scoped></style>
