<template>
  <div>
    <div v-if="!propertyPageView" class="flex w-full flex-row items-center justify-between p-6 pb-0">
      <h4 class="text-subhead-3 md:text-h4">General Info</h4>
      <div class="hidden flex-row items-center md:flex">
        <UiButtonGhost
          v-for="(brochure, index) in item.brochures"
          :id="`download_brochure_${index}`"
          :key="index"
          class="!text-sm !text-black-100"
          @click="useDownloadFile(brochure.src, brochure.title)"
        >
          <UiIcon name="file" />
          <span class="underline">{{ brochure.title }}</span>
        </UiButtonGhost>
      </div>
    </div>
    <div class="flex items-start gap-3 rounded-2xl bg-white p-6">
      <div :class="propertyPageView ? 'w-9/12' : 'w-full'">
        <div class="flex flex-wrap gap-2 md:flex-nowrap md:gap-6">
          <div class="flex w-full flex-col gap-2 md:w-1/2">
            <PropertyTabsInfoCard
              v-if="maxPriceMeasureBase || minPriceMeasureBase"
              :label="`Price Per ${useGetUserMeasure()}`"
              class="!min-h-[50px] !py-1"
            >
              <div>
                <span class="text-subhead-3">AED</span> min
                {{ aedFormatter.format(Number(minPriceMeasureBase)) || 0 }} - max
                {{ aedFormatter.format(Number(maxPriceMeasureBase)) }}
              </div>
              <div v-if="minPriceMeasureUser || maxPriceMeasureUser">
                <span class="text-subhead-3">{{ authStore.getUserCurrencyCode }}</span> min ~{{
                  aedFormatter.format(Number(minPriceMeasureUser)) || 0
                }}
                - max ~{{ aedFormatter.format(Number(maxPriceMeasureUser)) }}
              </div>
            </PropertyTabsInfoCard>
            <PropertyTabsInfoCard v-if="item.address" class="!min-h-[50px] !py-1" label="Location">{{
              item.address
            }}</PropertyTabsInfoCard>
            <PropertyTabsInfoCard v-if="item.exclusive_units_count" class="!min-h-[50px] !py-1" label="Exclusive units">
              <template #before-label>
                <UiTooltip name="stats">
                  <template #activator>
                    <UiIcon name="info-circle" class="cursor-pointer" />
                  </template>
                  <template #content> Number of Available and On Hold units </template>
                </UiTooltip>
              </template>
              {{ item.exclusive_units_count }}
            </PropertyTabsInfoCard>
          </div>
          <div class="flex w-full flex-col gap-2 md:w-1/2">
            <PropertyTabsInfoCard
              v-if="props.item?.price.min.base?.value"
              class="!min-h-[50px] !py-1"
              label="Unit price"
            >
              <div>
                <span class="text-subhead-3">AED</span> from
                {{ aedFormatter.format(Number(props.item?.price.min.base?.value)) }} - to
                {{ aedFormatter.format(Number(props.item?.price.max.base?.value)) }}
              </div>
              <div v-if="props.item?.price.min.user">
                <span class="text-subhead-3">{{ authStore.getUserCurrencyCode }}</span> from ~{{
                  aedFormatter.format(Number(props.item?.price.min.user?.value))
                }}
                - to ~{{ aedFormatter.format(Number(props.item?.price.max.user?.value)) }}
              </div>
            </PropertyTabsInfoCard>
            <PropertyTabsInfoCard v-if="convertType" class="!min-h-[50px] !py-1" label="Property type">{{
              convertType
            }}</PropertyTabsInfoCard>
            <PropertyTabsInfoCard v-if="item.service_charge" class="!min-h-[50px] !py-1" label="Service charge">
              <div>
                <span class="text-subhead-3">{{ item.service_charge?.base.currency }}</span>
                {{ aedFormatter.format(Number(item.service_charge.base.price)) }}/{{ useGetUserMeasure() }}
              </div>
              <div v-if="item.service_charge.user">
                <span class="text-subhead-3">{{ item.service_charge.user.currency }}</span>
                {{ aedFormatter.format(Number(item.service_charge.user?.price)) }}/{{ useGetUserMeasure() }}
              </div>
            </PropertyTabsInfoCard>
          </div>
        </div>
        <div class="mt-6 w-full">
          <ClientOnly>
            <Collapse :when="isFull" class="height-transition" :base-height="isMobile ? 0 : 135">
              <div ref="descriptionRef">{{ item.description.en }}</div>
            </Collapse>
            <UiButtonGhost v-if="needToHideText" id="show-more" class="!bg-transparent !p-0 md:mt-4" @click="showMore">
              {{ isMobile ? `Description` : `Show ${isFull ? 'less' : 'more'}` }}
              <UiIcon name="chevron-big-filled-down" :class="{ 'rotate-180': isFull }"></UiIcon>
            </UiButtonGhost>
          </ClientOnly>
        </div>
      </div>
      <PropertyCard
        v-if="propertyPageView"
        :item
        class="w-3/12"
        :hide-share-button="!!authStore.getVisitorToken"
        :brochure-is-loading="brochureIsLoading"
        @open-developer="openDeveloper"
        @create-offer="createOffer"
        @download="downloadBrochure"
      />
    </div>
    <div v-if="item && propertyPageView" class="mt-6 flex flex-col items-start gap-6 md:flex-row">
      <PropertyGallery :item class="w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import type { PropertyTypes } from '@/types'
import { useAuthStore } from '~/store/auth'
import { useUiStore } from '@/store/ui'
import { POPUPS } from '@/components/dynamic/maps'

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  maximumFractionDigits: 3,
})

const emits = defineEmits(['open-developer'])

type Props = {
  item: PropertyTypes.PropertyDetailed
  isMobile?: boolean
  propertyPageView?: boolean
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const uiStore = useUiStore()

const isFull = ref(false)

const descriptionRef = ref<HTMLElement>()
const needToHideText = ref(false)
const brochureIsLoading = ref<boolean>(false)

onMounted(() => {
  setTimeout(() => {
    needToHideText.value = Number(descriptionRef.value?.offsetHeight) > 135 || props.isMobile
  }, 200)
})

const minPriceMeasureBase = computed(() => {
  const bedroomWithMinPrice = minBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.min_price_m2.base?.price)
  )
  const min = bedroomWithMinPrice?.min_price_m2.base?.price
  if (isNaN(Number(min))) return 0
  return min
})
const maxPriceMeasureBase = computed(() => {
  const bedroomWithMaxPrice = maxBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.max_price_m2.base?.price)
  )

  const max = bedroomWithMaxPrice?.max_price_m2.base?.price

  if (isNaN(Number(max))) return 0
  return max
})

const minPriceMeasureUser = computed(() => {
  const bedroomWithMinPrice = minBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.min_price_m2.user?.price || '0')
  )
  const min = bedroomWithMinPrice?.min_price_m2.user?.price
  if (isNaN(Number(min))) return 0
  return min
})
const maxPriceMeasureUser = computed(() => {
  const bedroomWithMaxPrice = maxBy(props.item.br_prices, (item: PropertyTypes.BedroomPrice) =>
    parseFloat(item.max_price_m2.user?.price || '0')
  )

  const max = bedroomWithMaxPrice?.max_price_m2.user?.price

  if (isNaN(Number(max))) return 0
  return max
})

const convertType = computed(() => {
  return props.item.type
    ?.split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})

const showMore = () => {
  isFull.value = !isFull.value
}

const openDeveloper = () => {
  emits('open-developer')
}

const createOffer = () => {
  uiStore.showPopup(POPUPS.PROPERTY_CREATE_OFFER, { item: props.item })
}

const downloadBrochureArchive = async (id: string) => {
  brochureIsLoading.value = true
  const response = await useDownloadBrochures(id)

  const blob = new Blob([response as unknown as BlobPart], { type: 'application/zip' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = `${props.item?.title?.en}-brochures.zip`
  link.dispatchEvent(new MouseEvent('click'))
  window.URL.revokeObjectURL(link.href)
  brochureIsLoading.value = false
}

const downloadBrochure = () => {
  if (props.item.brochures) {
    props.item.brochures?.length === 1
      ? useDownloadFile(props.item.brochures[0].src, props.item.brochures[0].title)
      : downloadBrochureArchive(props.item.complex_id)
  }
}
</script>

<style scoped></style>
