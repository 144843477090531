<template>
  <div class="flex min-h-8 w-full py-1 md:min-h-14">
    <div class="flex flex-col items-start gap-1">
      <p
        :class="[
          planItem.isFee
            ? 'text-caption md:text-subhead-4 rounded-lg bg-primary-10 px-1 py-[2px] text-primary-100'
            : 'text-caption md:text-subhead-2',
          isMobile ? 'ml-7' : 'ml-8',
        ]"
      >
        {{ planItem.name }}
      </p>

      <p class="text-caption-2 md:text-body-2 text-black-60" :class="[isMobile ? 'ml-7' : 'ml-8']">
        <span v-if="planItem.date"> {{ planItem.date }}</span>
        <span v-if="planItem.payments">, {{ planItem.payments }} payments</span>
        <span v-if="planItem.details">{{ planItem.details }}</span>
      </p>
    </div>
    <span class="text-caption-2 md:text-body ml-auto text-black-70">{{ planItem.percent }}</span>
  </div>
</template>

<script setup lang="ts">
import type { PaymentPlanItem } from '~/types/properties'

defineProps<{
  planItem: PaymentPlanItem
}>()

const isMobile = inject('isMobile', false)
</script>

<style scoped></style>
