<template>
  <div class="w-full md:w-4/12 md:p-0" :class="{ 'px-3 pb-3': !publicStyle }">
    <div
      class="size-full overflow-hidden rounded-2xl bg-white"
      :class="publicStyle ? 'md:border md:border-black-20' : 'border border-black-20'"
    >
      <div v-if="item" class="mb-3 md:mb-4">
        <div
          class="flex items-center justify-between border-b border-black-10 px-4 py-3"
          :class="publicStyle ? 'bg-white md:bg-black-03' : 'bg-black-03'"
        >
          <p class="text-subhead-4 md:text-headline-4 pr-10 text-left text-black-100 md:pr-20">
            {{ item.payment_plans[activeSlide].title }}
          </p>
        </div>
      </div>

      <div class="px-3 pb-2 md:px-6 md:pb-4">
        <ClientOnly>
          <Swiper
            ref="swiper"
            :modules="[SwiperNavigation]"
            navigation
            :space-between="16"
            class="!overflow-y-visible"
            @slide-change="onSlideChange"
          >
            <SwiperSlide v-for="(plan, planIndex) in paymentPlans" :key="planIndex" class="!w-full">
              <div class="flex flex-col gap-2 md:gap-1">
                <PropertyTabsPaymentPlanList
                  v-for="(planGroup, groupIndex) in plan"
                  :key="groupIndex"
                  :plan-group
                  :active-slide
                  :hide-vertical-line="groupIndex === plan.length - 1"
                  :group-index
                  :plan-index
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Swiper as ISwiper } from 'swiper'
import type { PaymentPlanGroupFormatted, PropertyDetailed } from '~/types/properties'
import 'swiper/css'

defineProps<{
  item: PropertyDetailed
  paymentPlans: PaymentPlanGroupFormatted[][]
  publicStyle?: boolean
}>()

const emit = defineEmits<{
  (e: 'active-slide-change', value: number): void
}>()

const activeSlide = ref(0)

const onSlideChange = (swiper: ISwiper) => {
  activeSlide.value = swiper.realIndex

  emit('active-slide-change', activeSlide.value)
}
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply -top-7 mt-0 size-6 -translate-y-full;

      @media screen and (max-width: 1024px) {
        @apply -top-6 size-4;
      }

      &:after {
        @apply text-sm text-black-100;
      }

      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }

    &-button-prev {
      @apply right-6;
      @apply left-auto;
    }

    &-button-next {
      @apply -right-2;

      @media screen and (max-width: 1024px) {
        @apply right-0;
      }
    }
  }
}
</style>
